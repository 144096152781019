.contact-directory {
  width: 100%;
  min-height: 2000px;
  padding: 10px;
  background-color: #fff;
}

.title {
  font-size: 18px;
  margin-bottom: 10px;
}

.search-input {
  width: 50%;
  padding: 6px;
  font-size: 14px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
}

.group-select {
  width: 50%;
  padding: 6px;
  font-size: 14px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
}

.contact-list {
  max-height: 80vh;
  overflow-y: auto;
}

.contact-card {
  display: flex;
  align-items: center;
  padding: 8px;
  margin-bottom: 5px;
  background-color: #f8f9fa;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.contact-initials {
  width: 40px;
  height: 40px;
  color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  margin-right: 10px;
  flex-shrink: 0;
}

.contact-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.contact-details {
  display: flex;
  flex-direction: column;
}

.contact-name-directory {
  font-weight: bold;
  margin-bottom: 4px;
  font-size: 14px;
}

.contact-phone {
  font-size: 0.9em;
  color: #888;
}

.whatsapp-icon {
  color: #25D366;
  font-size: 1.5em;
  margin-left: 10px;
  cursor: pointer;
}


.whatsapp-icon:hover {
  opacity: 0.8;
}

.contact-list::-webkit-scrollbar {
  width: 6px;
}

.contact-list::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 10px;
}

.contact-list::-webkit-scrollbar-track {
  background-color: #f8f9fa;
}
